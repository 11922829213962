import React from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { Auth0Provider } from "@auth0/auth0-react"

import Layout from '../components/layout'
import Logout from '../components/Logout'
import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'


class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    // used by auth0 to tell us where we should resume...
    const s = queryString.parse(props.location.search).s;
    console.log('s', s);

    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      loading: 'is-loading'
    };
    
    this.handleOpenArticle = this.handleOpenArticle.bind(this);
    this.handleCloseArticle = this.handleCloseArticle.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);;
    this.handleClickOutside = this.handleClickOutside.bind(this);

    if (['buy', 'done', 'cancel'].indexOf(s) >= 0) {
      console.log('opening article', s);
      this.state.isArticleVisible = !this.state.isArticleVisible;
      this.state.article = s;
  
      setTimeout(() => {
        this.setState({
          timeout: !this.state.timeout
        });
      }, 325);
  
      setTimeout(() => {
        this.setState({
          articleTimeout: !this.state.articleTimeout
        });
      }, 350);
    }
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({
          loading: '',
        });
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article) {

    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)

  }

  handleCloseArticle() {

    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)

  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }

  render() {
    return (
      <Auth0Provider
        domain={this.props.data.site.siteMetadata.auth0Domain}
        clientId={this.props.data.site.siteMetadata.auth0ClientId}
        redirectUri={this.props.data.site.siteMetadata.auth0RedirectUri}
      >
        <Layout location={this.props.location}>
          <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
            <div id="wrapper">
              <Logout />
              <Header 
                redirectUri={this.props.data.site.siteMetadata.auth0RedirectUri}
                onOpenArticle={this.handleOpenArticle} 
                timeout={this.state.timeout} 
              />
              <Main
                isArticleVisible={this.state.isArticleVisible}
                timeout={this.state.timeout}
                articleTimeout={this.state.articleTimeout}
                article={this.state.article}
                onCloseArticle={this.handleCloseArticle}
                setWrapperRef={this.setWrapperRef}
                payfastUrl={this.props.data.site.siteMetadata.payfastUrl}
              />
              <Footer timeout={this.state.timeout} />
            </div>
            <div id="bg"></div>
          </div>
        </Layout>
      </Auth0Provider>
    )
  }
}

export const query = graphql`
  query PayfastQuery {
    site {
      siteMetadata {
        payfastUrl,
        auth0Domain,
        auth0ClientId,
        auth0RedirectUri
      }
    }
  }

`

export default IndexPage
