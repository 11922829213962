import PropTypes from 'prop-types'
import React from 'react'
import { withAuth0 } from '@auth0/auth0-react';

import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {

  constructor(props) {
    super(props);

    this.payfastForm = React.createRef();
    this.serverName = React.createRef();

    this.handleSelection = this.handleSelection.bind(this);
    this.handleSignupSubmission = this.handleSignupSubmission.bind(this);
    this.isValidServerName = this.isValidServerName.bind(this);
    this.validateServerName = this.validateServerName.bind(this);
  }

  loadServerNameList() {
    return fetch('/.netlify/functions/list-server-names', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(response => {
      return response.json().then(serverNameList => {
        this.setState({ loading: false, serverNameList })
      });
    });
  }

  isValidServerName(serverName) {
    if (serverName.length < 2 || serverName.length > 63) {
      return false;
    }
    if (serverName.length + '.minespace.co.za'.length > 255) {
      return false;
    }
    return /^[-a-z0-9]*[a-z0-9]+$/i.test(serverName);
  }
  
  validateServerName() {
    const serverName = this.serverName.current.value.toLowerCase();
    let isValid = this.isValidServerName(serverName);
    if (isValid && this.state && this.state.serverNameList) {
      const nameTaken = this.state.serverNameList.includes(serverName);
      this.setState({ nameTaken });
      isValid = isValid && !nameTaken;
    }
    this.serverName.current.className = isValid ? 'valid' : 'invalid';
    return isValid;
  }

  navigateToPayfast({ productCode, emailAddress, serverName }) {
    return fetch('/.netlify/functions/build-form-data', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productCode,
        emailAddress,
        serverName,
      }),
    }).then(response => {
      return response.json().then(payfastData => {
        this.setState({ payfastData }, () => {
          this.payfastForm.current.submit();
        });
        return 'submitted';
      });
    });
  }

  handleSignupSubmission(event) {
    this.setState({ loading: true });
    this.loadServerNameList().then(() => { 
      if (this.validateServerName()) {
        return this.navigateToPayfast({
          productCode: this.state.selection,
          emailAddress: this.props.auth0.user.email,
          serverName: this.serverName.current.value,
        });
      } else {
        return 'invalid';
      }
    }).catch(error => {
      // TODO Better error handling...
      console.log(error);
      throw error;
    }).finally(() => {
      this.setState({ loading: false })
    });      
  }

  // user props here: https://auth0.com/docs/users/user-profile-structure#user-profile-attributes

  handleSelection(selection, event) {
    const isValid = this.isValidServerName(this.serverName.current.value);
    if (!isValid) {
      event.preventDefault();
      return;
    }
   
    // TODO Check isloading and error
    if (!this.props.auth0.isAuthenticated) {
      this.props.auth0.loginWithRedirect();
    } 
    this.setState({ selection });
    this.handleSignupSubmission();
  }

  render() {
    let close = (
      <div
        role="button"
        className="close"
        aria-label="close"
        tabIndex={0}
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={(e) => {
          console.debug('onKeyDown e', e)
          if (e.key === 'Escape') {
            this.props.onCloseArticle()
          }
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="buy"
          className={`${this.props.article === 'buy' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">What shall we call it?</h2>

          <div>
            <form>
              <div className="field first">
                <label role="none">Owner</label> 
                <span>{this.props.auth0.user && this.props.auth0.user.email}</span>
              </div>
              <div className="field">
                <label htmlFor="server_name">Server Name</label>
                <input ref={this.serverName} onChange={this.validateServerName} type="text" name="server_name" id="server_name" />
                {this.state && this.state.nameTaken && <p>Name taken</p>}
              </div>
              <div className="actions">
                <input type="button" value="Rent a server" onClick={this.handleSelection.bind(this, 'micro')} />
              </div>
            </form>
          </div>
          
          <div style={{display: 'none'}}>
            <form ref={this.payfastForm} method='post' action={this.props.payfastUrl}>
              {this.state && this.state.payfastData && this.state.payfastData.map(field => 
              <input type="hidden" key={field[0]} name={field[0]} value={field[1]} />)}
            </form>
          </div>

          {close}
        </article>

        <article
          id="done"
          className={`${this.props.article === 'done' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Success!</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            We are now provisioning your server. You can take a look here: <a href="https://portal.minespace.co.za">portal.minespace.co.za</a>
          </p>
          {close}
        </article>

        <article
          id="cancel"
          className={`${this.props.article === 'cancel' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Payment Cancelled</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Your cash remains safely hidden in your vault.
          </p>
          <p>
            What changed your mind?
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  payfastUrl: PropTypes.string.isRequired,
  auth0: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
    }),
    error: PropTypes.shape({
      name: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      stack: PropTypes.string,
    }),
    getAccessTokenSilently: PropTypes.func.isRequired,
    getAccessTokenWithPopup: PropTypes.func.isRequired,
    getIdTokenClaims: PropTypes.func.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
    loginWithPopup: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
}

export default withAuth0(Main)
