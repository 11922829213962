import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";

import person from '../images/person-24px.svg'

import '../assets/scss/main.scss'

const Logout = () => {
    // TODO Handle `error` from useAuth0 too...
    const { isAuthenticated, logout } = useAuth0();

    return (
        isAuthenticated && (
        <button className="logoutIcon" onClick={() => {
            logout({ returnTo: window.location.origin }); 
        }}>
            <img src={person} alt="Logout" />
        </button>
        )
    );
}

export default Logout
