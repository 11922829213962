import PropTypes from 'prop-types'
import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";

import logo from '../images/minescape-logo.svg'

const Header = props => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
        <img src={logo} width="88" height="88" alt="Logo" />
      <div className="content">
        <div className="inner">
          <h1>Minespace</h1>
          <p>
            Rent a server... You know you want one!
          </p>
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <button
              onClick={() => {
                if (isAuthenticated) {
                  props.onOpenArticle('buy');
                } else {
                  loginWithRedirect({ redirectUri: `${props.redirectUri}/?s=buy` });
                }
              }
            }
            >
              Do it!
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
  redirectUri: PropTypes.string.isRequired,
}

export default Header
